import React from 'react';

import heroLine from '../assets/images/hero-line.png';
import aboutThumb from '../assets/images/about-thumb.png';
import about1 from "../assets/images/About-us-1.png"
import about2 from "../assets/images/About-us-2.png"
import about3 from "../assets/images/About-us-3.png"
import team from "../assets/images/venture1.jpg"
import team2 from "../assets/images/venture2.jpg"
import team3 from "../assets/images/venture3.jpeg"




import { Link } from 'react-router-dom';
const AboutUs = () => (


    <section className="about-us-area pb-100 mt-5" id="about">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 desktoporder1">
                    <div className="about-content">
                        <img loading="lazy" src={heroLine} alt="" />
                        <span>About us</span>

                        <h3 className="title" style={{ fontSize: "32px", lineHeight: "40px" }}>We Communicate Your Story, And Craft Experiences </h3>
                        <p>A team of ad wizards who obsess over creating engaging content craft customized advertising solutions for campaigns that drive results. Passion drives us to deliver for your audience. We seek perfection yet strive to learn with every story we provide. We are Haappeningads.</p>



                        <Link className="main-btn" to={'/about'}>Learn More</Link>
                    </div>
                </div>
                <div className="col-lg-7 desktoporder2">
                    <div className="about-thumb animated wow fadeInRight" data-wow-duration="3000ms" data-wow-delay="0ms">
                        {/* <img loading="lazy" src={aboutThumb} alt="" className='aboutimg aboutimgmobile' /> */}
                        <div className="position-relative desktopimg">
                            <img src={team} className='img-fluid about1 ' alt="" />
                            <img src={team2} className='img-fluid about2' alt="" />
                            <img src={team3} className='img-fluid about3' alt="" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default AboutUs;
