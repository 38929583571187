import React from 'react'
import InsidePageBanner from '../Component/InsidePageBanner'
import Header from '../Layout/Header'
import pricingArrow from '../assets/images/pricing-arrow.png'; // Importing the image
import OurProject from '../Component/OurProject';
import InsideTestimonial from '../Component/InsideTestimonial';
import Footer from '../Layout/Footer';
import SEO from '../SeoComponent/Seo';

function Pricing() {
    return (
        <>
            <SEO title="Happeningads - Connecting Brands With Consumer
" description="We are an advertising agency with expertise in both online and offline advertising with proven track record.
" keywords="Happeningads, Advertising Agency
" />
            <Header />
            <InsidePageBanner title="Pricing" />



            <section className="pricing-2-area pricing-page-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-9">
                            <div className="pricing-2-item mt-30 animated wow fadeInLeft" data-wow-duration="3000ms" data-wow-delay="0ms">
                                <div className="item">
                                    <h4 className="title">Startup</h4>
                                    <div className="text">
                                        <p>Brand is a collective of young, up-and-coming lateral thinkers who would describe</p>
                                    </div>
                                    <ul>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Idea making</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Information architecture</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Customer journey map</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> <span>Road map</span> depend of your project</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Design system</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Wireframe</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> <span>User persona</span> depend of your project</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Visual design</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Interaction</li>
                                    </ul>
                                    <a className="main-btn" href="#">Free Consultation</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-9">
                            <div className="pricing-2-item item-2 mt-30 animated wow fadeInRight" data-wow-duration="3000ms" data-wow-delay="0ms">
                                <div className="item">
                                    <h4 className="title">Startup</h4>
                                    <div className="text">
                                        <p>Brand is a collective of young, up-and-coming lateral thinkers who would describe</p>
                                    </div>
                                    <ul>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Idea making</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Information architecture</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Customer journey map</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> <span>Road map</span> depend of your project</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Design system</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Wireframe</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> <span>User persona</span> depend of your project</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Visual design</li>
                                        <li><img loading="lazy" src={pricingArrow} alt="Arrow" /> Interaction</li>
                                    </ul>
                                    <a className="main-btn" href="#">Free Consultation</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <OurProject /> */}
            <InsideTestimonial />
            <Footer />

        </>
    )
}

export default Pricing
